<template>
  <div>
    <transaction-list />
  </div>
</template>

<script>
import TransactionList from '@/components/money/transaction/TransactionList.vue'

export default {
  components: { TransactionList },
  name: "TransactionListView"
}
</script>