<template>
  <div class="transaction-list">
    <transaction-details-modal 
      :transaction="selectedTransaction"
      :open-modal="detailModal"
      @modalClosed="closeDetailsModal"
    />
    <transaction-form-modal
      :transaction="selectedTransaction"
      :open-modal="createUpdateModal"
      :update="false"
      @modalClosed="createUpdateModal = false, selectedTransaction = {}, detailModal = false"
    />
    <v-btn
      dark
      fixed
      bottom
      right
      fab
      class="mb-8 mr-5"
      color="primary"
      @click="createUpdateModal = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <form @submit.prevent="updateFilteredData">
      <!-- Filters here -->
      <v-row dense>
        <v-col
          cols="6"
          md="4"
        >
          <v-select
            clearable
            :disabled="loading"
            :items="categories"
            v-model="filterData.category_id"
            item-text="name"
            item-value="id"
            label="Category"
          />
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <v-select
            clearable
            :disabled="loading"
            :items="wallets"
            v-model="filterData.wallet_id"
            item-text="name"
            item-value="id"
            label="Wallet"
          />
        </v-col>
        <v-col
          cols="6"
          md="4"
        >
          <v-select
            clearable
            :disabled="loading"
            :items="trxTypes"
            v-model="filterData.trx_type"
            item-text="name"
            item-value="value"
            label="Transaction Type"
          />
        </v-col>

        <v-col
          cols="6"
          md="4"
        >
          <v-text-field
            clearable
            :disabled="loading"
            v-model.lazy="filterData.note"
            label="Note"
            placeholder="Enter text to search"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            ref="menu"
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            :return-value.sync="filterData.date_range"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                clearable
                v-model="filterData.date_range"
                label="Select date range"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="selectedDateRange"
              range
              no-title
              scrollable
            >
              <v-spacer />
              <v-btn
                text
                color="primary"
                @click="dateMenu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(selectedDateRange)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="filter-buttons"
        >
          <v-btn
            :disabled="loading || Object.keys(filterData).length === 0"
            type="submit"
            :loading="loading"
            class="mt-md-3"
            color="primary"
          >
            Search
          </v-btn>
          <v-btn
            @click="exportTransactions"
            :disabled="loading"
            type="button"
            :loading="loading"
            class="mt-md-3"
            color="info"
          >
            <v-icon
              right
              dark
            >
              mdi-download
            </v-icon>
            &nbsp;
            Export
          </v-btn>
        </v-col>
      </v-row>
    </form>

    <h2 class="title">
      Transactions
    </h2>
    <v-divider />
    <v-data-table
      dense
      @click:row="openDetailsModal"
      :items-per-page="-1"
      loading-text="Loading... Please wait"
      :loading="loading"
      :hide-default-footer="true"
      :headers="headers"
      :items="transactions"
      item-key="id"
      class="elevation-1"
    >
      <template #[`item.category.name`]="{ item }">
        {{ item.category ? item.category.name : 'N/A' }}
      </template>
      <template #[`item.created_at`]="{ item }">
        {{ item.created_at | dateToHuman }}
      </template>
      <template #[`item.transaction_time`]="{ item }">
        {{ item.transaction_time | dateToHuman }}
      </template>
      <template v-slot:footer>
        <v-divider />
        <p class="text-center">
          <strong>Total Amount: {{ totalTransactionAmount.toFixed(2) }}</strong>
        </p>
      </template>
    </v-data-table>

    <v-btn
      v-if="transactionPagination.next"
      class="mt-2 white--text"
      block
      color="red lighten-2"
      @click="getMoreTransactions"
    >
      Load More
    </v-btn>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TransactionDetailsModal from './TransactionDetailsModal.vue'
import TransactionFormModal from './TransactionFormModal.vue'

export default {
  components: { TransactionDetailsModal, TransactionFormModal },
  name: "TransactionList",
  data () {
    return {
      createUpdateModal: false,
      detailModal: false,
      selectedTransaction: {},
      selectedDateRange: [],
      headers: [
        {text: "Wallet", value: "wallet.name", sortable: true},
        {text: "Category", value: "category.name", sortable: true},
        {text: "Note", value: "note"},
        {text: "Amount", value: "amount", sortable: true},
        {text: "Type", value: "trx_type", sortable: true},
        {text: "Transaction Time", value: "transaction_time", sortable: true},
        {text: "Creation Time", value: "created_at", sortable: true},
      ],
      loading: false,
      trxTypes: [
        { name: "Expense", value: "expense" },
        { name: "Income", value: "income" },
        { name: "Transfer", value: "transfer" },
      ],
      filterData: {},
      dateMenu: false
    }
  },
  computed: {
    ...mapGetters(["wallets",]),
    transactions () {
      return this.$store.getters.transactions || []
    },
    totalTransactionAmount () {
      return this.transactions.length > 0 ? this.transactions.map(i=>i.amount).reduce((a,b)=>a+b) : 0
    },
    transactionPagination () {
      return this.$store.getters.transactionPagination || {}
    },
    categories () {
      const data = []
      this.$store.getters.flatCategoriesWithoutParent.map((item) => {
        if (item.parent && item.parent.id) {
          data.push({...item, name: `${item.name} (${item.parent.name})`})
        } else {
          data.push({...item})
        }
      })
      return data
    }
  },
  async created () {
    this.loading = true
    await this.$store.dispatch("getAllTransactionsFromAPI", {noAppend: true, noNext: true})
    this.loading = false
    if (this.$route.query.transactionId) {
      await this.selectTransactionFromRouter(this.$route.query.transactionId)
    }

    this.loading = true
    await Promise.all([
      this.$store.dispatch('getAllWalletsFromAPI'),
      this.$store.dispatch('getAllCategoriesFromAPI'),
    ])
    this.loading = false
  },
  methods: {
    async selectTransactionFromRouter (transactionId) {
      const _transaction = this.transactions.find((item) => {
        return item.id === transactionId
      })

      if (!_transaction) {
        const response = await this.$store.dispatch("getSingleTransactionFromAPI", transactionId)
        if (response && response.status === 200) {
          this.selectedTransaction = response.data
        } else {
          this.$ebus.$emit("newToastMessage", {
            message: response.msg,
            color: "error"
          });
        }
      } else {
        this.selectedTransaction = _transaction
      }
      this.detailModal = true
    },
    prepareTransactionTitle(trans) {
      let text = `${trans.trx_type}: ${trans.amount} `
      // {{ trans.trx_type }}: {{ trans.amount }} {{ trans.trx_type === 'expense' ? 'from' : 'in' }} {{ trans.wallet.name }}
      text += trans.trx_type === 'expense' ? 'from ' : 'in '
      text += trans.wallet.name
      return text
    },
    openDetailsModal (transaction) {
      this.$router.push({path: this.$route.path, query: {transactionId: transaction.id} })
    },
    closeDetailsModal () {
      this.$router.push({path: this.$route.path})
      this.selectedTransaction = {}
      this.detailModal = false
    },
    async getMoreTransactions() {
      if (this.transactionPagination.next && !this.loading) {
        this.loading = true
        await this.$store.dispatch("getAllTransactionsFromAPI", {})
        this.loading = false
      }
    },
    async updateFilteredData () {
      this.loading = true
      await this.$store.dispatch("getAllTransactionsFromAPI", {search: true, filters: this.filterData})
      this.loading = false
    },
    async exportTransactions () {
      this.loading = true
      try {
        const response = await this.$store.dispatch("exportTransactions", {search: true, filters: this.filterData})
        let fileName = response.headers["content-disposition"].split("filename=")[1];
        if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
            window.navigator.msSaveOrOpenBlob(new Blob([response.data],
                    { type: 'text/csv' }
                ),
                fileName
            );
        } else {
            const url = window.URL.createObjectURL(new Blob([response.data],
                { type: 'text/csv' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',
                response.headers["content-disposition"].split("filename=")[1]);
            document.body.appendChild(link);
            link.click();
        }
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    }
  },
  filters: {
    dateToHuman (value) {
      return new Date(value).toDateString()
    },
  },
  watch: {
    '$route.query.transactionId' (newValue) {
      if (newValue) {
        this.selectTransactionFromRouter(newValue)
      }
    }
  }
}
</script>

<style scoped>
.transaction-list {
  /* margin-top: 24px; */
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 1rem;
}
.transaction-content {
  cursor: pointer;
}

.filter-buttons button {
  width: 48%;
}
.filter-buttons button:first-child {
  margin-right: 10px;
}
</style>