<template>
  <v-dialog
    v-model="open"
    scrollable
    max-width="760px"
  >
    <v-card>
      <v-card-title>{{ transaction.note }} of "{{ transaction.amount }}"</v-card-title>
      <v-divider />
      <v-card-text>
        <key-value-pair-data
          :data="transaction"
          :date-time-fields="dateTimeFields"
          :ignored-fields="ignoredFieldsToShow"
          :photo-fields="photoFields"
          :sort="true"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          small
          color="red"
          class="white--text"
          @click="reverseTransaction"
        >
          <v-icon small>
            mdi-undo
          </v-icon>
          Reverse
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import KeyValuePairData from '../../common/KeyValuePairData.vue'

export default {
  components: { KeyValuePairData },
	name: "TransactionDetailsModal",
	props: {
		transaction: {
			type: Object,
			required: true
		},
		openModal: {
			type: Boolean,
			default: false
		}
	},
	data () {
		return {
			open: false,
			ignoredFieldsToShow: ["id"],
			dateTimeFields: ["created_at", "updated_at", "transaction_time",],
      photoFields: ["photo"]
		}
	},
	methods: {
		async reverseTransaction() {
			const response = await this.$store.dispatch("reverseSingleTransactionFromAPI", this.transaction.id)
			if (response && response.status === 201) {
				this.$ebus.$emit("newToastMessage", {
					message: response.msg,
					color: "success"
				});
			} else {
				this.$ebus.$emit("newToastMessage", {
					message: response.msg,
					color: "error"
				});
			}
			this.open = false
		}
	},
	watch: {
		openModal (newValue) {
      this.open = newValue
		},
		open (newValue) {
			if (newValue === false) {
				this.$emit("modalClosed")
			}
		}
	}
}
</script>