<template>
  <v-dialog
    v-model="open"
    scrollable
    max-width="760px"
  >
    <v-card>
      <v-card-title>
        {{ update ? `Update "${transaction.name}"` : 'New transaction' }}
      </v-card-title>
      <v-card-text>
        <transaction-form
          :transaction="transaction"
          :update="update"
          @closeModal="open = false"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TransactionForm from "./TransactionForm.vue"

export default {
  name: "TransactionFormModal",
  components: { TransactionForm },
  props: {
    transaction: {
      type: Object,
      required: false,
      default: () => { }
    },
    update: {
      type: Boolean,
      default: false
    },
    openModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false,
    }
  },
  watch: {
    openModal(newValue) {
      if (newValue) {
        this.open = true
      }
    },
    open(newValue) {
      if (newValue === false) {
        this.$emit("modalClosed")
      }
    }
  }
}
</script>