<template>
  <v-form
    ref="form"
    lazy-validation
    v-model="valid"
    @submit.prevent="saveTransaction"
  >
    <v-select
      :rules="validationRules.required"
      :items="trxTypes"
      v-model="formData.trx_type"
      item-text="name"
      item-value="value"
      label="Transaction Type"
    />
    <v-select
      :rules="validationRules.required"
      :items="activeWallets"
      v-model="formData.wallet_id"
      item-text="name"
      item-value="id"
      :label="formData.trx_type === 'transfer' ? 'From Wallet' : 'Wallet'"
    />
    <p 
      class="text--red text-subtitle-2" 
      v-if="walletSelected.id"
    >
      Remaining Balance: {{ walletSelected.current_amount }}
    </p>
    <v-select
      v-if="formData.trx_type === 'transfer'"
      :rules="validationRules.to_wallet_id"
      :items="activeWallets"
      v-model="formData.to_wallet_id"
      item-text="name"
      item-value="id"
      label="To Wallet"
    />
    <v-select
      clearable
      :items="categories"
      v-model="formData.category_id"
      item-text="name"
      item-value="id"
      label="Category"
    >
      <template v-slot:prepend-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              v-model="categorySelectSearchText"
              placeholder="Search"
              @input="getCategories"
            />
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-2" />
      </template>
    </v-select>
    <v-text-field
      :rules="validationRules.note"
      label="Note"
      v-model="formData.note"
      placeholder="About your transaction"
    />
    <v-text-field
      :rules="validationRules.amount"
      type="number"
      label="Amount"
      v-model="formData.amount"
      placeholder="Transaction Amount"
    />
    <single-date-input
      label="Transaction Time"
      :value="formData.transaction_time"
      @dateSelected="(_date) => formData.transaction_time = _date"
    />
    <v-file-input
      show-size
      :rules="validationRules.img"
      accept="image/*"
      placeholder="Upload a photo"
      prepend-icon="mdi-camera"
      label="Photo"
      v-model="transactionPhoto"
    />
    <p
      class="mt-4 red pa-2 white--text"
      v-if="errorResponse"
    >
      {{ errorResponse }}
    </p>
    <v-row>
      <v-btn
        :loading="loading"
        :disabled="loading"
        type="submit"
        class="mt-4"
        :color="update ? 'info' : 'primary'"
      >
        {{ update ? 'Update' : 'Save' }}
      </v-btn>
      <v-spacer />
      <v-btn
        @click="saveAndContinue"
        :loading="loading"
        :disabled="loading"
        class="mt-4"
        color="info"
      >
        Save & Continue
      </v-btn>
      <v-spacer />
      <v-btn
        @click="$emit('closeModal')"
        :loading="loading"
        :disabled="loading"
        class="mt-4"
        color="red"
      >
        Close
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'
import SingleDateInput from "@/components/common/SingleDateInput";
import { MAX_TRX_IMAGE_SIZE } from "@/utils/constants"

export default {
  name: "TransactionForm",
  components: { SingleDateInput },
  data() {
    return {
      valid: false,
      loading: false,
      formData: this.transaction.id ? this.transaction : { trx_type: 'expense', transaction_time: new Date().toISOString() },
      transactionPhoto: null,
      walletSelected: {},
      errorResponse: null,
      trxTypes: [
        { name: "Expense", value: "expense" },
        { name: "Income", value: "income" },
        { name: "Transfer", value: "transfer" },
      ],
      validationRules: {
        img: [
          value => !value || value.size < MAX_TRX_IMAGE_SIZE || `Image size should be less than ${MAX_TRX_IMAGE_SIZE / 1000 / 1000} MB!`,
        ],
        required: [
          value => !!value || 'This field is required.',
        ],
        note: [
          value => !!value || 'This field is required.',
          value => (value && value.length <= 32) || 'Note must less than 32 characters.',
        ],
        amount: [
          value => !!value || 'This field is required.',
          value => (value <= this.walletSelected.current_amount || this.formData.trx_type === "income") || 'Amount is not available on your selected wallet.',
        ],
        to_wallet_id: [
          value => value !== this.formData.wallet_id || 'You must select two different wallet',
        ]
      },
      categorySelectSearchText: "",
      categories: this._categories || []
    }
  },
  props: {
    transaction: {
      type: Object,
      required: false,
      default: () => { }
    },
    update: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["activeWallets"]),
  },
  mounted() {
    this.getCategories()
  },
  methods: {
    async saveAndContinue() {
      await this.saveTransaction({oneMore: true})
      this.errorResponse = null
    },
    async saveTransaction({oneMore=false}) {
      this.loading = true
      if (this.$refs.form.validate()) {
        if (this.transactionPhoto) {
          const imageUploadResp = await this.$store.dispatch("uploadImage", this.transactionPhoto);
          if (imageUploadResp && imageUploadResp.status === 200) {
            this.formData.photo = imageUploadResp.data['relative_path']
          }
          this.$ebus.$emit("newToastMessage", {
            message: imageUploadResp.data.msg,
          })
        }
        const response = await this.$store.dispatch("createOrUpdateTransactionAction", this.formData)
        if (response && (response.status === 201 || response.status === 200)) {
          this.transactionPhoto = null
          if (!oneMore) {
            this.formData = { trx_type: 'expense' }
            this.$refs.form.resetValidation()
            this.walletSelected = {}
            this.$emit("closeModal")
          }
        } else {
          this.errorResponse = response.data.msg
        }
        this.$ebus.$emit("newToastMessage", {
          message: response.msg,
        })
        this.loading = false
      }
      else {
        this.loading = false
      }

    },
    getCategories() {
      const data = []
      this.$store.getters.flatCategoriesWithoutParent.filter(i => i.name.toLowerCase().includes(this.categorySelectSearchText.toLowerCase())).map((item) => {
        if (item.parent && item.parent.id) {
          data.push({ ...item, name: `${item.name} (${item.parent.name})` })
        } else {
          data.push({ ...item })
        }
      })
      this.categories = data
    }
  },
  watch: {
    'transaction.id'() {
      this.formData = JSON.parse(JSON.stringify(this.transaction))
    },
    'formData.wallet_id'(selectedWalletId) {
      if (selectedWalletId) {
        this.walletSelected = this.activeWallets.find((item) => {
          if (item.id === selectedWalletId) {
            return item
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.vdatetime {
  width: 150px;
  outline: none;
  border: 1px solid #aaa;
  padding: 6px 28px;
  color: #aaa;
}
</style>