<template>
  <div class="single-dateinput">
    <v-datetime-picker
      :label="label"
      v-model="date"
    >
      <template #dateIcon>
        <v-icon>mdi-calendar</v-icon>
      </template>
      <template #timeIcon>
        <v-icon>mdi-clock</v-icon>
      </template>
    </v-datetime-picker>
  </div>
</template>


<script>

export default {
  name: "SingleDateInput",
  props: {
    label: {
      type: String,
      default: "Pick a date"
    },
    value: {
      type: String,
      default: ""
    },
    hasTime: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      date: this.value ? new Date(this.value) : '',
      modal: false
    }
  },
  watch: {
    date (newValue) {
      const _parsed = new Date(newValue).toISOString()
      this.$emit("dateSelected", _parsed)
      console.log(_parsed)
    }
  }
}
</script>